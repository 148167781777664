import React from 'react'
import { BusinessProfileType, PremiumTemplates, SocialMedia } from 'utils/constants'
import { PremiumUser, PremiumUserArgs } from 'models/premium-user'
import { toContentfulRaw } from 'utils/format'
import LayoutContainer from 'components/templates/premium/base/Layout'
import AvatarImg from 'assets/images/premium/p-01/avatar.png'

const defaultData: PremiumUserArgs = {
  isDemo: true,
  id: '1',
  name: 'Jorge',
  businessProfileType: BusinessProfileType.Personal,
  template: PremiumTemplates.Base_01,
  firstLastName: 'Robles',
  secondLastName: 'Jara',
  nameToDisplay: 'Jorge Robles',
  avatarUrl: {
    file: {
      url: AvatarImg,
    },
  },
  metadata: {
    pageTitle: 'Professional P01',
  },
  jobs: [
    {
      id: '1',
      title: 'Ingeniero de Software',
    },
    {
      id: '2',
      title: 'Project Manager',
    },
    {
      id: '3',
      title: 'Analista de Negocios',
    },
  ],
  contact: {
    id: '1',
    phoneNumbers: ['+51901422305'],
    email: 'hola@playu.pe',
    addresses: [
      {
        id: '1',
        description: {
          description: 'Lima, Peru',
        },
        link: 'https://www.facebook.com/playu.shop',
      },
    ],
    socialMedia: [
      {
        order: 1,
        id: '1',
        type: SocialMedia.Facebook,
        link: 'https://www.facebook.com/playu.shop',
      },
      {
        order: 2,
        id: '2',
        type: SocialMedia.Instagram,
        link: 'https://instagram.com/playu.shop',
      },
      {
        order: 3,
        id: '3',
        type: SocialMedia.Youtube,
        link: 'https://www.youtube.com/channel/UCne87oRuqn9eEIZQ63A5EQg',
      },
      {
        order: 4,
        id: '4',
        type: SocialMedia.Linkedin,
        link: '#',
      },
    ],
  },
  services: [
    {
      id: '1',
      icon: 'fab fa-joomla',
      title: 'Desarrollo Web',
      description: {
        raw: toContentfulRaw('Implementación de aplicaciones webs escalables.'),
      },
    },
    {
      id: '2',
      icon: 'fab fa-slack',
      title: 'Desarrollo de Juegos',
      description: {
        raw: toContentfulRaw('Desarrollo de juegos en Java o Unity.'),
      },
    },
    {
      id: '3',
      icon: 'fab fa-yelp',
      title: 'Implementación de Service Desk',
      description: {
        raw: toContentfulRaw('Implementación bajo los estándares de Itil.'),
      },
    },
    {
      id: '4',
      icon: 'fab fa-viacoin',
      title: 'Desarrollo de Apps',
      description: {
        raw: toContentfulRaw('Desarrollo de aplicaciones móviles en Android y Ios.'),
      },
    },
  ],
}

const PageComponent: React.FC = () => {
  return <LayoutContainer user={new PremiumUser(defaultData)} template={PremiumTemplates.Base_01} />
}

export default PageComponent
